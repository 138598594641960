<template>
  <div class="banner_consult">
    <video
      autoplay
      muted
      loop
      playsinline
      poster="https://t1.kakaocdn.net/dkt_corp/service/banner_consulting_pc.png"
      class="video_visual"
    >
      <source
        src="https://t1.kakaocdn.net/dkt_corp/service/video_consulting.mp4"
        type="video/mp4"
      />
    </video>
    <div class="wrap_banner">
      <strong class="tit_banner">1분이면 돼요!<br />전문가에게 무료 컨설팅 받아보세요</strong>
      <ButtonConsult
        :type="'banner'"
        :logInfo="props.logInfo"
      />
    </div>
  </div>
</template>
<script setup>
const props = defineProps({
  logInfo: {
    type: Object,
    default() {
      return {};
    },
  },
});
</script>
<style lang="scss" scoped>
.banner_consult {
  overflow: hidden;
  position: relative;
  width: 100%;
  .video_visual {
    width: auto;
    height: 428px;
    vertical-align: top;
  }
  .wrap_banner {
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);
    display: flex;
    justify-content: space-between;
    align-items: center;
    width: 100%;
    max-width: 1264px;
    padding: 0 80px;
    box-sizing: border-box;
  }
  .tit_banner {
    font-size: 30px;
    letter-spacing: -0.5px;
    color: #242424;
  }
}
@media (min-width: 1920px) {
  .banner_consult {
    height: 428px;
    overflow: hidden;
    .video_visual {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translateY(-50%);
      width: 100%;
      height: auto;
    }
  }
}
@media screen and (max-width: 1023px) {
  .banner_consult {
    .video_visual {
      height: 526px;
    }
    .wrap_banner {
      flex-direction: column;
      padding: 0 20px;
    }
    .tit_banner {
      font-size: 22px;
      text-align: center;
      margin-bottom: 24px;
    }
  }
}
</style>
